import axios from "axios";
import React, { useState } from "react";
import config from "../config";

function CreateFormPage() {
	const [n, setN] = useState(1);

	return (
		<div>
			<input id="title-input" placeholder="title" />
			<br />
			<textarea id="letter-input" placeholder="letter" />
			<div className="products">
				Products:{" "}
				<input
					type="number"
					defaultValue={1}
					onChange={(e) => {
						if (!isNaN(e.target.value)) setN(Number(e.target.value));
					}}
				/>
				<br />
				{Array(n)
					.fill(0)
					.map((_, i) => (
						<form
							id={`product-form-${i}`}
							className="product-form"
							onSubmit={(e) => e.preventDefault()}
						>
							<input placeholder="product id" name="itemId" />
							<label htmlFor={`image-input-${i}`}>Image: </label>
							<input type="file" id={`image-input-${i}`} name="file" />
						</form>
					))}
				<br />
				<button
					onClick={() => {
						console.log("clicked");
						let data = {};
						data.title = document.getElementById("title-input").value;
						if (!data.title) return;
						const letter = document.getElementById("letter-input").value;
						if (letter) data.letter = letter.split("\n");
						data.items = [];

						console.log({ data });

						const forms = document.querySelectorAll(`.product-form`);
						for (var i = 0; i < n; i++) {
							if (!(forms[i].itemId.value && forms[i].file.value)) {
								console.log("incomplete");
								return;
							}

							const id = forms[i].itemId.value;
							let path;

							const index = i;

							console.log(forms[i].file);
							// submitting file
							const formData = new FormData();
							const imagefile = document.querySelector(`#image-input-${i}`);
							formData.append("file", imagefile.files[0]);
							axios
								.post(`${config.apiLocation}/file/upload`, formData, {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								})
								.then((res) => {
									console.log(res.data.data.name);
									path = res.data.data.name;
									data.items.push({ id, path, index });
									if (data.items.length === n) {
										// sorting items
										data.items = data.items.sort(function (a, b) {
											var x = a.index;
											var y = b.index;
											return x < y ? -1 : x > y ? 1 : 0;
										});
										// submitting final
										axios
											.post(`${config.apiLocation}/admin/form`, data, {
												headers: { password: localStorage.password },
											})
											.then((res) => {
												console.log(res.data);
												window.location = "/admin";
											})
											.catch((err) => {
												console.error(err);
												alert("error submitting form");
											});
									}
								})
								.catch((err) => {
									console.error(err);
									alert("error uploading images");
								});
						}
					}}
				>
					Submit
				</button>
			</div>
		</div>
	);
}

export default CreateFormPage;
