import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "./pages/AdminLogin";
import AdminPage from "./pages/AdminPage";
import CreateFormPage from "./pages/CreateFormPage";
import FormPage from "./pages/FormPage";
import ThankYouPage from "./pages/ThankYouPage";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<>
							<h3>.</h3>
						</>
					}
				/>
				<Route path="form/*" element={<FormPage />} />
				<Route path="admin" element={<AdminPage />} />
				<Route path="admin/login" element={<AdminLogin />} />
				<Route path="create-form" element={<CreateFormPage />} />
				<Route path="thank-you" element={<ThankYouPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
