import React from "react";
import "./ThankYouPage.css";

function ThankYouPage() {
	return (
		<div className="thank-you">
			<div className="center">
				<img src={require("./ty.png")} alt="thank you" />
				<div className="text">We really appreciate you giving us a moment of your time today</div>
			</div>
		</div>
	);
}

export default ThankYouPage;
