import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";

function AdminPage() {
	const [forms, setForms] = useState([]);

	useEffect(() => {
		axios
			.get(`${config.apiLocation}/admin/form`, {
				headers: { password: localStorage.password },
			})
			.then((res) => {
				console.log(res.data);
				setForms(res.data);
			});
	}, []);

	return localStorage.password ? (
		<div>
			<button
				onClick={() => {
					localStorage.clear();
					window.location = "/";
				}}
			>
				log out
			</button>
			<button
				onClick={() => {
					window.location = "/create-form";
				}}
			>
				new form
			</button>

			<hr />

			<form
				onSubmit={(e) => {
					e.preventDefault();
					const formData = new FormData();
					const usersFile = document.querySelector("#users-input");
					formData.append("file", usersFile.files[0]);
					axios
						.post(`${config.apiLocation}/file/upload`, formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((res) => {
							console.log(res.data.data.name);
							axios
								.post(
									`${config.apiLocation}/admin/users`,
									{ file: res.data.data.name },
									{
										headers: { password: localStorage.password },
									}
								)
								.then((res) => {
									console.log(res.data);
									alert(`${res.data.length} users created`);
									window.location = window.location;
								})
								.catch((err) => {
									console.error(err);
									alert("error submitting form");
								});
						})
						.catch((err) => {
							console.error(err);
							alert("error uploading file");
						});
				}}
			>
				Users CSV file:{" "}
				<input required type="file" id="users-input" name="file" />
				<input type="submit" />
			</form>

			<hr />
			{forms.length > 0 ? (
				<>
					<h2>Forms</h2>
					{forms.map((form) => (
						<div key={form._id}>
							<h3>{form.title}</h3>{" "}
							<button
								onClick={() => {
									axios
										.post(
											`${config.apiLocation}/admin/toggle-form/${form._id}`,
											{},
											{ headers: { password: localStorage.password } }
										)
										.then((res) => {
											window.location = window.location;
										});
								}}
							>
								{form.isActive ? "Active" : "Inactive"}
							</button>{" "}
							{form.responses.length} responses{" "}
							<button
								onClick={() => {
									axios
										.delete(`${config.apiLocation}/admin/form/${form._id}`, {
											headers: { password: localStorage.password },
										})
										.then((res) => {
											window.location = window.location;
										});
								}}
							>
								delete
							</button>{" "}
							<a href={`/form/${form._id}`}>view form</a>{" "}
							<a
								href={`${config.apiLocation}/user/download-responses/${form._id}`}
							>
								download results
							</a>
						</div>
					))}
				</>
			) : null}
		</div>
	) : null;
}

export default AdminPage;
