import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config";
import "./FormPage.css";

function FormPage() {
	const [email, setEmail] = useState();
	const [user, setUser] = useState();
	const [title, setTitle] = useState();

	const [products, setProducts] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [responded, setResponded] = useState(false);
	const [letter, setLetter] = useState(false);

	const date = new Date();

	useEffect(() => {
		axios
			.get(
				`${config.apiLocation}/user/form/title/${
					window.location.pathname.split("/").slice(-1)[0]
				}`
			)
			.then((res) => {
				setTitle(res.data.title);
			});
	}, []);

	if (!user)
		return (
			<div className="email-page">
				<img src={require("./logo.png")} alt="logo" />
				<form
					className="email-form"
					onSubmit={(e) => {
						e.preventDefault();
						const inputValue = e.target.email.value;
						setEmail(inputValue);
						console.log(inputValue);

						axios
							.get(`${config.apiLocation}/user/self`, {
								headers: { email: inputValue },
							})
							.then((res) => {
								console.log(res.data);
								setUser(res.data);

								// loading form
								const formID = window.location.pathname.split("/").slice(-1)[0];
								axios
									.get(`${config.apiLocation}/user/form/${formID}`, {
										headers: { email: inputValue },
									})
									.then((res) => {
										console.log(res.data);
										setLetter(res.data.letter);
										setResponded(res.data.submitted);
										setIsActive(res.data.isActive);
										setProducts(res.data.items);
									});
							})
							.catch((err) => {
								alert("please use registered email id to access the survrey");
							});
					}}
				>
					<div className="input-wrapper">
						<input
							autoFocus
							placeholder="Type registered email and press enter"
							type="email"
							name="email"
							required
						/>
					</div>
				</form>
				<div className="title-display">{title}</div>
			</div>
		);

	if (user)
		return (
			<div className="form-page">
				<div className="header">
					<div className="header-main">
						<span className="title">{title}</span>
						<img className="logo" src={require("./logo.png")} alt="logo" />
					</div>
				</div>
				<div className="main-content">
					{letter ? (
						<div className="letter">
							{/* <div className="salutation">Dear XYZ</div> */}
							<div className="letter-body">
								{letter.map((paragraph) => (
									<p key={paragraph}>{paragraph}</p>
								))}
							</div>
						</div>
					) : null}
					{/* user info */}
					<form className="user-details">
						<div className="heading">YOUR DETAILS</div>
						<div className="input-wrapper">
							<label htmlFor="name-iput">Name</label>
							<input disabled value={user.name} id="name-input" />
						</div>
						<div className="input-wrapper">
							<label htmlFor="store-iput">Store</label>
							<input disabled value={user.shop} id="store-input" />
						</div>
						<div className="input-wrapper">
							<label htmlFor="address-iput">Address</label>
							<input disabled value={user.address} id="address-input" />
						</div>
						<div className="input-wrapper">
							<label htmlFor="email-iput">Email</label>
							<input
								disabled
								value={user.email}
								id="email-input"
								type="email"
							/>
						</div>
					</form>
					{products.length > 0 && !responded && isActive ? (
						<>
							<form
								className="survey"
								onSubmit={(e) => {
									e.preventDefault();
									const responses = products.map((product) => {
										return e.target[`product-${product.id}`].value;
									});
									console.log({ responses });
									axios
										.post(
											`${config.apiLocation}/user/form/${
												window.location.pathname.split("/").slice(-1)[0]
											}`,
											{ responses },
											{ headers: { email: email } }
										)
										.then((res) => {
											console.log(res.data);
											setResponded(true);
											window.location = "/thank-you";
										})
										.catch((err) => {
											console.error(err);
											console.log(err.response.data.msg);
											alert(err.response.data.msg);
										});
								}}
							>
								<div className="survey-header">TAKE THE SURVEY</div>
								<div className="survey-subtitle">
									SELECT ANY ONE APPLICABLE OPTION
								</div>
								<div className="products">
									{products.map((product) => (
										<div className="product" key={product.id}>
											<img
												src={`${config.apiLocation}/file/download/${product.path}`}
												alt={`product ${product.id}`}
											/>
											<div className="options">
												{/* {options.map((option, index) => (
										<div className="input-wrapper" key={option}>
											<input
												id={`input-${product.id}-${index}`}
												type="checkbox"
												value="true"
											/>
											<label htmlFor={`input-${product.id}-${index}`}>
												{option}
											</label>
										</div>
									))} */}
												<div className="input-heading">SOLD WELL</div>
												<div className="input-wrapper">
													<input
														required
														id={`input-${product.id}-0`}
														type="radio"
														name={`product-${product.id}`}
														value="sold well,in stock"
													/>
													<label htmlFor={`input-${product.id}-0`}>
														In stock
													</label>
												</div>
												<div className="input-wrapper">
													<input
														required
														id={`input-${product.id}-1`}
														type="radio"
														name={`product-${product.id}`}
														value="sold well,not in stock"
													/>
													<label htmlFor={`input-${product.id}-1`}>
														Not in stock
													</label>
												</div>
												<div className="input-heading">NOT SOLD WELL</div>
												<div className="input-wrapper">
													<input
														required
														id={`input-${product.id}-2`}
														type="radio"
														name={`product-${product.id}`}
														value="not sold well,in stock"
													/>
													<label htmlFor={`input-${product.id}-2`}>
														In stock
													</label>
												</div>
												<div className="input-wrapper">
													<input
														required
														id={`input-${product.id}-3`}
														type="radio"
														name={`product-${product.id}`}
														value="not sold well,did not order"
													/>
													<label htmlFor={`input-${product.id}-3`}>
														Did not order
													</label>
												</div>
											</div>
										</div>
									))}
									<button className="submit-button">SUBMIT</button>
								</div>
							</form>
						</>
					) : (
						<>
							{responded ? (
								<>
									<div className="survey">
										<div className="survey-header">RESPONSE RECORDED</div>
										<div className="survey-subtitle">
											YOU HAVE ALREADY SUBMITTED YOUR RESPONSE
										</div>
									</div>
								</>
							) : (
								<>
									{!isActive ? (
										<>
											<div className="survey">
												<div className="survey-header">FORM CLOSED</div>
												<div className="survey-subtitle">
													THIS FORM IS NO LONGER ACCEPTING RESPONSES
												</div>
											</div>
										</>
									) : null}
								</>
							)}
						</>
					)}
				</div>
				<footer>Copyright Platinum Guild India, {date.getFullYear()}</footer>
			</div>
		);
}

export default FormPage;
