import axios from "axios";
import React from "react";
import config from "../config";

function AdminLogin() {
	return (
		<div>
			<input id="password-input" type="password" placeholder="password" />
			<button
				onClick={() => {
					const password = document.getElementById("password-input").value;

					axios
						.post(
							`${config.apiLocation}/admin/login`,
							{},
							{ headers: { password } }
						)
						.then((res) => {
							console.log(res);
							localStorage.password = password;
							window.location = "/admin";
						})
						.catch((err) => {
							console.error(err);
							alert("incorrect password");
						});
				}}
			>
				Login
			</button>
		</div>
	);
}

export default AdminLogin;
